import {
	HttpClient,
	HttpErrorResponse,
	HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'app/core/config/app.config.service';
import { NGXLogger } from 'ngx-logger';
import { catchError, Observable, retry, tap, throwError, timeout } from 'rxjs';
import urlJoin from 'url-join';

@Injectable({
	providedIn: 'root',
})
export class UltramarineApiService {
	constructor(
		private _logger: NGXLogger,
		private _httpClient: HttpClient,
		private _configService: AppConfigService,
	) {}

	public get<T>(
		url: string,
		params?: any,
		withCredentials: boolean = true,
	): Observable<T> {
		// Log
		this._logger.info('get > called with: ', {
			url: url,
			withCredentials: withCredentials,
		});

		const apiBaseUrl = urlJoin(
			this._configService.config.apiBaseUrl,
			this._configService.config.apiBasePrefix,
		);

		return this._httpClient
			.get<T>(urlJoin(apiBaseUrl, url), {
				withCredentials: withCredentials,
				params: params,
			})
			.pipe(
				retry(this._configService.config.appApiCallRetryCount),
				timeout(this._configService.config.appApiCallTimeout),
				tap(() => this._logger.debug('get > success')),
				catchError((error) => this.handleError('get', error)),
			);
	}

	public download(
		url: string,
		params?: any,
		withCredentials: boolean = true,
	): Observable<HttpResponse<Blob>> {
		// Log
		this._logger.info('download > called with: ', {
			url: url,
			withCredentials: withCredentials,
		});

		const apiBaseUrl = urlJoin(
			this._configService.config.apiBaseUrl,
			this._configService.config.apiBasePrefix,
		);

		return this._httpClient
			.get(urlJoin(apiBaseUrl, url), {
				withCredentials: withCredentials,
				params: params,
				responseType: 'blob',
				observe: 'response',
			})
			.pipe(
				retry(this._configService.config.appApiCallRetryCount),
				timeout(this._configService.config.appApiCallTimeout),
				tap(() => this._logger.debug('download > success')),
				catchError((error) => this.handleError('download', error)),
			);
	}

	public post<T>(
		url: string,
		data: any,
		withCredentials: boolean = true,
	): Observable<T> {
		// Log
		this._logger.info('post > called with: ', {
			url: url,
			data: data,
			withCredentials: withCredentials,
		});

		const apiBaseUrl = urlJoin(
			this._configService.config.apiBaseUrl,
			this._configService.config.apiBasePrefix,
		);

		// Log
		this._logger.info('post > calling api on: ', urlJoin(apiBaseUrl, url));

		return this._httpClient
			.post<T>(urlJoin(apiBaseUrl, url), data, {
				withCredentials: withCredentials,
			})
			.pipe(
				// retry(this._configService.config.appApiCallRetryCount),
				// timeout(this._configService.config.appApiCallTimeout),
				tap(() => this._logger.info('post > success')),
				catchError((error) => this.handleError('post', error)),
			);
	}

	public put<T>(
		url: string,
		data: any,
		withCredentials: boolean = true,
	): Observable<T> {
		// Log
		this._logger.debug('put > url: ', url);

		const apiBaseUrl = urlJoin(
			this._configService.config.apiBaseUrl,
			this._configService.config.apiBasePrefix,
		);

		return this._httpClient
			.put<T>(urlJoin(apiBaseUrl, url), data, {
				withCredentials: withCredentials,
			})
			.pipe(
				retry(this._configService.config.appApiCallRetryCount),
				timeout(this._configService.config.appApiCallTimeout),
				tap(() => this._logger.debug('put > success')),
				catchError((error) => this.handleError('put', error)),
			);
	}

	public patch<T>(
		url: string,
		data: any,
		withCredentials: boolean = true,
	): Observable<T> {
		// Log
		this._logger.debug('patch > url: ', url);

		const apiBaseUrl = urlJoin(
			this._configService.config.apiBaseUrl,
			this._configService.config.apiBasePrefix,
		);

		return this._httpClient
			.patch<T>(urlJoin(apiBaseUrl, url), data, {
				withCredentials: withCredentials,
			})
			.pipe(
				retry(this._configService.config.appApiCallRetryCount),
				timeout(this._configService.config.appApiCallTimeout),
				tap(() => this._logger.debug('patch > success')),
				catchError((error) => this.handleError('patch', error)),
			);
	}

	public delete<T>(
		url: string,
		withCredentials: boolean = true,
	): Observable<T> {
		// Log
		this._logger.debug('delete > url: ', url);

		const apiBaseUrl = urlJoin(
			this._configService.config.apiBaseUrl,
			this._configService.config.apiBasePrefix,
		);

		return this._httpClient
			.delete<T>(urlJoin(apiBaseUrl, url), {
				withCredentials: withCredentials,
			})
			.pipe(
				retry(this._configService.config.appApiCallRetryCount),
				timeout(this._configService.config.appApiCallTimeout),
				tap(() => this._logger.debug('delete > success')),
				catchError((error) => this.handleError('delete', error)),
			);
	}

	public batchDelete<T>(
		url: string,
		ids: number[],
		withCredentials: boolean = true,
	): Observable<T> {
		// Log
		this._logger.debug('batch delete > url: ', url);

		const apiBaseUrl = urlJoin(
			this._configService.config.apiBaseUrl,
			this._configService.config.apiBasePrefix,
		);

		return this._httpClient
			.delete<T>(urlJoin(apiBaseUrl, url), {
				body: { ids: ids },
				withCredentials: withCredentials,
			})
			.pipe(
				retry(this._configService.config.appApiCallRetryCount),
				timeout(this._configService.config.appApiCallTimeout),
				tap(() => this._logger.debug('batchDelete > success')),
				catchError((error) => this.handleError('batchDelete', error)),
			);
	}

	private handleError(
		method: string,
		error: HttpErrorResponse,
	): Observable<never> {
		const errorMessage = error.error.message;

		this._logger.error(`${method} > error: `, errorMessage);

		return throwError(() => {
			return error.error;
		});
	}
}
