import { NgClass, NgIf } from '@angular/common';
import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { FocusDirective } from '../../../directives/focus.directive';

@Component({
	selector: 'button[ultra-primary]',
	templateUrl: 'ultra-primary.component.html',
	standalone: true,
	imports: [MatIconModule, NgIf, MatTooltipModule, TranslocoModule, NgClass],
	hostDirectives: [FocusDirective],
})
export class UltraPrimaryButtonComponent {
	@Input() disabled: boolean | null = null;
	@Input() color: 'primary' | 'inverted' | null = null;
	@Input() svgIcon?: string;
	@Input() content?: string;

	@HostBinding('attr.disabled') get isDisabled() {
		return this.disabled === true ? '' : null;
	}

	// Set host classes conditionally based on the color and disabled state
	@HostBinding('class') get className(): string {
		const baseClasses =
			'rounded-full border h-fit flex px-3 py-1.5 justify-center gap-1 font-normal whitespace-nowrap duration-100';

		const enabledClasses =
			this.color === 'primary'
				? 'bg-brand text-white border-transparent'
				: this.color === 'inverted'
					? 'bg-gray-800 text-white hover:bg-hover border-gray-800'
					: 'cursor-pointer hover:bg-hover';

		const disabledClasses = 'cursor-not-allowed text-gray-500';

		return `${baseClasses} ${
			this.disabled && !(this.color === 'primary' || this.color === 'inverted')
				? disabledClasses
				: this.disabled && this.color === 'primary'
					? 'bg-opacity-500'
					: this.disabled && this.color === 'inverted'
						? 'bg-gray-700'
						: enabledClasses
		}`;
	}
}
