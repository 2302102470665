import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
	selector: '[focus]',
	standalone: true,
})
export class FocusDirective {
	constructor(
		private el: ElementRef,
		private renderer: Renderer2,
	) {}

	@HostListener('focusin')
	onFocusIn() {
		this.renderer.addClass(this.el.nativeElement, '!border-blue-500');
		this.renderer.addClass(this.el.nativeElement, 'focused');
	}

	@HostListener('focusout')
	onFocusOut() {
		this.renderer.removeClass(this.el.nativeElement, '!border-blue-500');
		this.renderer.removeClass(this.el.nativeElement, 'focused');
	}
}
